import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchFreeTrialCount } from "../../redux/ReduxSlices/freeTrialSlice.jsx";
import "../../styles/model.css";
import { plansFree } from "../../utils/freeUserSubscriptionPlan.js";
import { plansRegular } from "../../utils/regularSubscriptionPlan.js";
import PaymentPopup from "../Payment/PaymentPopup";
import { IoIosCloseCircle } from "react-icons/io";
import { UserProfileDetails } from '../../redux/ReduxSlices/ProfileSlice';
import showNotification from "../../hooks/notificationHook.js";

function PricingPlans() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const [isYearly, setIsYearly] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  const { userDetails } = useSelector((state) => state.user);
  const subscriptionPlanName = userDetails?.subscription_plan_name;
  const { freeTrailData, loading, error } = useSelector(
    (state) => state.freeTrial
  );

  useEffect(() => {
    dispatch(fetchFreeTrialCount());
  }, [dispatch]);

  const handleSubmit = (plan) => {
    setSelectedPlan(plan);
    if (plan.monthlyPrice === "Free") {


      showNotification("info", "Your are in free trail, you can generate up to 4 songs")
    } else {
      setShowPaymentPopup(true);
    }
  };

  const closeModal = () => {
    setShowPaymentPopup(false);
    setSelectedPlan(null);
  };


  return (
    <div className="min-h-screen text-white flex flex-col items-center bg-black p-8 max-md:p-4 mt-20 max-md:mt-10" >
      <motion.div
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col justify-center mt-4 items-center p-8 w-full text-center text-gray-200 rounded-xl shadow-[0px_-2px_10px_rgba(233,223,255,0.3)] max-md:px-5 max-md:max-w-full"
      >
        <div className="flex flex-col max-w-full w-full md:w-[768px]">
          <h2 className="self-center text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight max-md:max-w-full max-md:text-3xl">
            Choose the plan that works for you.
          </h2>
          <p className="mt-6 text-lg leading-7 max-md:max-w-full">
            Pick the perfect plan for you and start enjoying all our website has
            to offer! Whether you're looking for essential features or the full
            experience, we have a plan that fits your needs. Dive in and make
            the most of your subscription!{" "}
          </p>
        </div>
      </motion.div>

      {freeTrailData.free_trial_count !== 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 mt-14">
          {plansRegular.map((plan, index) => (
            <div
              key={index}
              className="p-2 sm:p-8 rounded-lg flex flex-col items-cente shadow-lg"
            >
              <h3 className="text-2xl sm:text-2xl text-center text-transparent font-extrabold mb-4 bg-clip-text bg-gradient-to-r from-green-500 via-teal-500 to-pink-500">
                {plan.name}
              </h3>
              <p className="text-xl sm:text-2xl text-center font-bold mb-4">
                {" "}
                {plan.monthlyPrice === "Free" ? "" : "Rs."}{" "}
                {isYearly ? plan.yearlyPrice : plan.monthlyPrice}
              </p>
              <button
                onClick={() => handleSubmit(plan)}
                className="mb-4 px-6 py-4 rounded-full custom-button w-full"
              >
                {plan.status ? plan.status : "Subscribe"}
              </button>
              <ul className="text-sm space-y-2">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-start">
                    <span className="mr-2">•</span> {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 max-md:gap-8 mt-14 max-md:mt-6">
          {plansFree.map((plan1, index) => (
            <div
              key={index}
              className="p-2 sm:p-8 rounded-lg flex flex-col items-cente shadow-lg"
            >
              <h3 className="text-2xl sm:text-2xl text-center text-transparent font-extrabold mb-4 bg-clip-text bg-gradient-to-r from-green-500 via-teal-500 to-pink-500">
                {plan1.name}
              </h3>
              <p className="text-xl sm:text-2xl text-center font-bold mb-4">
                {" "}
                {plan1.monthlyPrice === "Free" ? "" : "Rs."}{" "}
                {isYearly ? plan1.yearlyPrice : plan1.monthlyPrice}
              </p>
              {/* <button
                onClick={() => handleSubmit(plan1)}
                className="mb-4 px-6 py-4 rounded-full custom-button  w-full"
              >
                {plan1.status ? plan1.status : "Subscribe"}
              </button> */}
              <button
                onClick={() => handleSubmit(plan1)}
                className="mb-4 px-6 py-4 rounded-full custom-button w-full"
              >
                {subscriptionPlanName === plan1.planName
                  ? "Active"
                  : plan1.status || "Subscribe"}
              </button>
              <ul className="text-sm space-y-2">
                {plan1.features.map((feature, i) => (
                  <li key={i} className="flex items-start">
                    <span className="mr-2">•</span> {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      {showPaymentPopup && (
        <div className="modal-overlay">
          <div className="modal-content-payment">
            <button onClick={closeModal} className="close-button-payment" style={{ fontSize: "26px" }}>
              <IoIosCloseCircle />
            </button>

            <PaymentPopup selectedPlan={selectedPlan} closeModal={closeModal} />
          </div>
        </div>
      )}

    </div>
  );
}

export default PricingPlans;
