import React from "react";
import { SiRazorpay } from "react-icons/si";

function SubscriptionDetails({ selectedPlan }) {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col items-start w-full max-md:mt-2 gap-8 max-md:gap-0">
        <div className="flex flex-col mt-4 max-md:w-full w-[416px]">
          <h1 className="text-3xl max-md:text-2xl font-bold bg-gradient-to-r from-[#a7e373] via-[#57d0c1] to-[#2c726c] bg-clip-text text-transparent">
            Subscribe to I Made a Song <br />
          </h1>
          <div className="bg-gray-700/40 rounded-xl p-4 transform transition-all hover:scale-105 duration-300 mt-16 max-md:mt-8">
            <div className="flex justify-between items-center mb-4">
              <span className="text-lg font-medium">I Made a Song</span>
              <span className="text-2xl font-bold text-emerald-400">Rs. {selectedPlan.monthlyPrice}</span>
            </div>
            <p className="text-gray-400 text-sm">
            {selectedPlan.name} and {selectedPlan.features[1]}
            </p>
          </div>
          {/* <p className="z-10 self-start mt-2 lg:mt-8 text-base font-medium leading-snug text-zinc-300 max-md:mt-4">
            {selectedPlan.name} and {selectedPlan.features[1]}
          </p> */}
          {/* <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/1ace08d81d1886966a1ba1a553e5525642703b4e277ab9ae4e132f87504cba7c?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&"
            alt=""
            className="object-contain mt-3.5 w-full aspect-[500] stroke-[1px] stroke-zinc-700 max-md:mt-6"
          /> */}
          <div className="border-t border-gray-700 pt-4 mt-24 max-md:mt-8">
                <div className="flex justify-between items-center">
                  <span className="text-gray-400">Subtotal</span>
                  <span className="text-xl font-bold text-emerald-400">Rs. {selectedPlan.monthlyPrice}</span>
                </div>
              </div>
        </div>
        {/* <div className="flex gap-10 self-stretch py-1.5 pr-12 mt-4 max-md:mt-0 w-full font-medium max-md:gap-4 max-md:pr-5">
          <div className="flex flex-col flex-1">
            <p className="text-sm leading-tight text-white max-md:text-base">
              I Made a Song
            </p>
            <p className="mt-1 text-xs leading-snug text-zinc-300 max-md:text-sm">
              Billed for {selectedPlan.name} and {selectedPlan.features[1]}
            </p>
          </div>
          <div className="flex flex-1 gap-6 self-start text-base leading-snug max-md:flex-col max-md:gap-2">
            <div className="gap-2 self-stretch px-3.5 whitespace-nowrap"></div>
            <p className="my-auto text-right text-white max-md:text-left">
              Rs. {selectedPlan.monthlyPrice}
            </p>
          </div>
        </div> */}

        {/* <div className="flex gap-10 self-stretch py-1.5 pr-12 mt-4 w-full font-medium max-md:gap-4 max-md:pr-5">
          <div className="flex flex-col flex-1">
            <p className="text-sm leading-tight text-white max-md:text-base">
              Subtotal
            </p>
          </div>
          <div className="flex flex-1 gap-6 self-start text-base leading-snug max-md:flex-col max-md:gap-2">
            <div className="gap-2 self-stretch px-3.5 whitespace-nowrap"></div>
            <p className="my-auto text-right text-white max-md:text-left">
              {" "}
              Rs. {selectedPlan.monthlyPrice}
            </p>
          </div>
        </div> */}
        <div className="flex items-center space-x-2 text-sm text-gray-400 max-md:mt-4">
                <img src="https://razorpay.com/favicon.png" alt="Razorpay" className="w-10 h-10" />
                <span>Powered by Razorpay</span>
                <span className="text-xs bg-gray-700 px-2 py-1 rounded">Secure Payment</span>
              </div>

              {/* <div className="flex flex-col mt-4 max-w-full font-medium leading-snug w-[416px]">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/d49ea5ac8d14424bb883f83e8d4c08bb/1ace08d81d1886966a1ba1a553e5525642703b4e277ab9ae4e132f87504cba7c?apiKey=d49ea5ac8d14424bb883f83e8d4c08bb&" alt="" className="object-contain z-10 w-full aspect-[500] stroke-[1px] stroke-zinc-700" />
    <div className="flex gap-5 justify-between mt-6">
        <button className="text-sm text-teal-300">Add Promotionla Code</button>
    </div>
</div> */}
              

        {/* <div className="flex items-center text-center mt-4 bg-black mb-4 max-md:mb-4  max-md:mt-4 justify-start space-x-4">
          <div className="h-10 flex justify-center items-center">
            <SiRazorpay className="text-white text-3xl" />
          </div>
          <div className="text-start">
            <h3 className="text-base font-semibold text-white">
              Powered by Razorpay
            </h3>
            <p className="text-sm text-gray-400">Secure Payment</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SubscriptionDetails;
