import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaCamera } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Webcam from "react-webcam";
import PageLoader from "../../components/PageLoader";
import {
  aiCloneStatus,
  getImageOpinion,
  processVoiceGeneration,
} from "../../redux/ReduxSlices/voicesSlice";
import ImageGenerationGuide from "./ImageGenerationGuide";
import UploadAudio from "./UploadAudio";
import { UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice";
import showNotification from "../../hooks/notificationHook";

export default function AudioCloning() {
  const [selectedAspectRatio, setSelectedAspectRatio] = useState("1:1");
  const { cloneLoading, error } = useSelector((state) => state.voices);
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audio, setAudio] = useState(null);
  const [audioName, setAudioName] = useState("");
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState({
    voice_id: "",
    name: "Select Audio",
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successModalmessage, setSuccessModalMessage] = useState("");
  const [title, setTitle] = useState("");
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const [checkAudio, setCheckAudio] = useState(false);
  //UPLOAD IMAGE
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isShowMoodalGuide, setIsShowModalGuide] = useState(false);
  const imageContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const modalRef = useRef();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const capturePhoto = async () => {
    if (webcamRef.current) {
      const photo = webcamRef.current.getScreenshot();
      const jpegFile = base64ToJpeg(photo);
      setPreview(photo);
      setImage(jpegFile);
      await dispatch(getImageOpinion(jpegFile));
      closeModal();
    }
  };
  const base64ToJpeg = (base64DataUrl) => {
    const base64 = base64DataUrl.split(",")[1];

    const binaryString = atob(base64);
    const binaryLength = binaryString.length;
    const binaryArray = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([binaryArray], { type: "image/jpeg" });

    const file = new File([blob], "captured-photo.jpg", { type: "image/jpeg" });

    return file;
  };

  const generateImage = async () => {
    setCheckAudio(true);
    if (
      userDetails.video_credit_point === 0 &&
      userDetails.video_free_trial_count === 0
    ) {
      setTimeout(() => {
        navigate("/pricing");
      }, 2000);
      return showNotification(
        "error",
        "You need to subscribe to generate videos!"
      );
    }
    if (!title) {
      showNotification("error", "Title is required.");

      return;
    }
    if (!image) {
      showNotification("error", "Please Select Image or Take Selfie.");

      return;
    }
    if (!audio) {
      showNotification("error", "Please Select Audio.");

      return;
    }
    if (!selectedAspectRatio) {
      showNotification("error", "Please Select Aspect ratio");

      return;
    }
    try {
      let allowGenerate = false;
      const croppedImage = await handleCrop();
      if (!croppedImage) {
        showNotification("error", "Failed to crop image. Please try again.");

        return;
      }

      const status = await dispatch(aiCloneStatus());
      if (
        status.payload.status === "Completed" ||
        status.payload.status === "Failed"
      ) {
        allowGenerate = true;
      }
      if (allowGenerate) {
        const result = await dispatch(
          processVoiceGeneration({
            croppedImage,
            audio,
            selectedAspectRatio,
            selectedOption,
            title,
          })
        ).unwrap();
        setImage(null);
        setAudio(null);
        setPreview(null);
        setTitle("");
        setSelectedOption({
          voice_id: "",
          name: "Select Audio",
        });
        setSuccessModalMessage(
          "Your video is being created and will take approximately 20-30 minutes. Please Check the Library 'Video Song' Section after 30 minutes. You can close this page and log back in after 30 minutes"
        );
        setIsSuccessModalOpen(true);
      } else {
        setImage(null);
        setAudio(null);
        setPreview(null);
        setTitle("");
        setSelectedOption({
          voice_id: "",
          name: "Select Audio",
        });

        setSuccessModalMessage(
          "Hang tight! We're on it. Your previous request is being processed and should be ready in about 8-9 minutes. Please check back in the library soon."
        );
        setIsSuccessModalOpen(true);
      }
      await dispatch(UserProfileDetails());
    } catch (error) {
      setImage(null);
      setAudio(null);
      setPreview(null);
      setTitle("");
      setSelectedOption({
        voice_id: "",
        name: "Select Audio",
      });
      setSuccessModalMessage("Failed to generate video. Try again later");
      setIsSuccessModalOpen(true);
      dispatch(UserProfileDetails());
    }
  };

  const openCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasWebcam = devices.some((device) => device.kind === "videoinput");
      if (!hasWebcam) {
        showNotification(
          "error",
          "No webcam detected. Please connect a webcam to continue."
        );
        return;
      }
      openModal(true);
    } catch (error) {
      showNotification(
        "error",
        "An error occurred while checking for webcam availability."
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: -2, behavior: "smooth" });
  }, []);

  const videoConstraints = {
    width: 300,
    height: 300,
    // facingMode: "user"
  };

  // upload image
  const handleCrop = async () => {
    if (!preview) {
      console.error("No image preview available.");
      return;
    }

    try {
      const croppedImage = await getCroppedImg(preview, croppedAreaPixels);
      setImage(croppedImage);
      return croppedImage;
    } catch (e) {
      console.error("Error cropping image:", e);
    }
  };

  const getCroppedImg = async (imageSrc, croppedAreaPixels) => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const croppedImage = new File([blob], "cropped-image.png", {
              type: "image/png",
            });
            resolve(croppedImage); // Return the cropped file
          } else {
            reject(new Error("Canvas is empty"));
          }
        }, "image/png");
      };

      image.onerror = () => reject(new Error("Failed to load image"));
    });
  };

  const handleInfoClick = () => {
    setIsShowModalGuide(true);
  };

  const handleClose = () => {
    setIsShowModalGuide(false);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleReset = () => {
    setImage(null);
    setPreview(null);
    setDragPosition({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const maxFileSize = 15 * 1024 * 1024; // 5 MB
    const validTypes = ["image/png", "image/webp", "image/jpeg"];

    if (file) {
      if (!validTypes.includes(file.type)) {
        showNotification(
          "error",
          "Invalid file type. Please upload a PNG, JPEG, or WebP image."
        );

        return;
      }

      if (file.size > maxFileSize) {
        showNotification(
          "error",
          "File size exceeds 5 MB. Please select a smaller file."
        );

        return;
      }

      setImage(file);
      await dispatch(getImageOpinion(file));
      setPreview(URL.createObjectURL(file)); // Create temporary URL for preview
    }
  };

  if (cloneLoading) {
    return <PageLoader />;
  }

  const handleDemoClick = () => {
    setVideoModalOpen(true);
  };

  const handleVideoCloseModal = () => {
    setVideoModalOpen(false);
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/\s/g, "");
    const regex = /^[\p{L}\p{N}\p{Z}\p{Emoji}]*$/u;
    if (trimmedValue.length > 30) {

      showNotification("error", "30 characters are not allowed (excluding spaces)!")

    } else if (!regex.test(value)) {

      showNotification("error", "Special characters are not allowed!")
    } else {
      setTitle(value);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-md:h-[87%] max-md:overflow-x-scroll max-md:mb-28">
        <div className="h-screen flex flex-col justify-start w-full p-3 lg:flex-row min-h-screen createSong max-md:mt-2 gap-6">
          <div className="w-full h-[93%] flex flex-col max-sm:mb-4 items-center overflow-auto max-md:overflow-y-hidden clone-component  text-gray-50  p-4 shadow-lg ">
            {/* <UploadImage
            handleDemoClick={handleDemoClick}
              image={image}
              setImage={setImage}
              preview={preview}
              setPreview={setPreview}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              webcamRef={webcamRef}
              openModal={openCamera}
              selectedAspectRatio={selectedAspectRatio}
              setSelectedAspectRatio={setSelectedAspectRatio}
              cropImage={cropImage}
              setCropImage={setCropImage}
            /> */}

            <div className="w-[65%] max-md:w-full relative bg-gradient-to-r from-yellow-200 via-teal-500 to-purple-500 text-white p-8 max-md:p-4 rounded-lg shadow-lg mb-4">
              {/* New Year Label Outside the Banner */}
              <div className="absolute -top-6 right-0 max-md:-right-2 m-4 text-sm bg-yellow-400 text-black px-3 py-1 rounded-full shadow-lg">
                🎉 A Choice You’ll Love 🎉
              </div>

              <h1 className="text-4xl max-md:text-2xl text-black sm:text-4xl font-bold text-center mb-2 max-md:mb-0 mt-0 max-md:mt-2">
                Create Your Singing Selfie
              </h1>
              <p className="text-xl text-black sm:text-2xl text-center font-bold">
                Friends Greetings
              </p>
              <p className="text-sm text-black sm:text-base text-center font-semibold">
                Start now by selecting a fresh Friends song collection from the
                tab below.
              </p>
            </div>

            <div className="w-2/5 max-md:w-full upload-image-container flex flex-col justify-center ">
              <div className="flex justify-between mb-6">
                <div>
                  <div className="text-xl font-bold">Singing Selfie</div>
                </div>
                <div className="flex gap-6">
                  <div>
                    <button
                      onClick={handleDemoClick}
                      className="text-white text-sm font-bold text-right px-2 py-0 rounded-md border border-gray-500  hover:text-gray-500"
                    >
                      View Demo
                    </button>
                  </div>
                  <div>
                    <span className="cursor-pointer" onClick={handleInfoClick}>
                      <AiOutlineQuestionCircle className="mt-1.5" size={18} />
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="block text-sm font-medium mb-2">
                  Upload Image Here
                </h3>
              </div>
              <div
                className="flex justify-center underline mb-2 text-teal-500 cursor-pointer max-md:text-sm"
                onClick={handleInfoClick}
              >
                Read the instructions carefully on how to upload a photo.
              </div>
              {preview ? (
                <div className="flex flex-col items-center justify-center ">
                  <div
                    className="relative w-[250px] h-[250px] rounded bg-red"
                    ref={imageContainerRef}
                  >
                    <Cropper
                      image={preview}
                      crop={crop}
                      zoom={zoom}
                      aspect={8 / 8}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      style={{ width: "100%", height: "100%" }}
                      objectFit="cover"
                      showGrid={false}
                    />
                  </div>
                  <button
                    className="mt-4 reset-button  bg-transparent text-white p-2 rounded-full"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <span className="text-gray-500">
                    Pinch to zoom in or out to crop the image.
                  </span>
                  {/* {selectedAspectRatio === "1:1" ? <div className="text-gray-400 text-base mt-4">For the best results when creating a singing selfie video, it is highly recommended to use a 1:1 aspect ratio.(Ensure the image is taken with the face clearly visible.)</div> : null} */}
                </div>
              ) : (
                <div className="border border-dashed border-gray-600 rounded-lg p-4 flex flex-col items-center justify-center">
                  <input
                    type="file"
                    accept="image/png, image/webp, image/jpeg"
                    multiple
                    onChange={handleImageChange}
                    className="hidden"
                    id="imagePicker"
                  />
                  <label
                    htmlFor="imagePicker"
                    className="cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600"
                  >
                    Upload Image
                  </label>
                  <span className="text-gray-400 text-sm mt-1 mb-1">OR</span>
                  <label
                    onClick={openCamera}
                    className="flex justify-center items-center gap-4 cursor-pointer bg-gray-700 text-sm font-semibold text-gray-300 py-2 px-4 rounded-lg hover:bg-gray-600"
                  >
                    {" "}
                    Click Picture
                    <div className="text-gray-500 cursor-pointer">
                      <FaCamera size={15} />
                    </div>
                  </label>
                </div>
              )}
              {isShowMoodalGuide && (
                <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center text-black shadow-lg ">
                  <div
                    ref={modalRef}
                    className="rounded-lg  model-margin sm: w-full sm:w-3/4 md:w-1/2 overflow-auto  border border-gray-700"
                  >
                    <ImageGenerationGuide onclose={handleClose} />
                  </div>
                </div>
              )}
            </div>

            <UploadAudio
              audio={audio}
              setAudio={setAudio}
              selectedOption={selectedOption}
              setAudioName={setAudioName}
              setSelectedOption={setSelectedOption}
              setCheckAudio={setCheckAudio}
              checkAudio={checkAudio}
            />

            {/* Todo Title  */}
            <div className="w-2/5 mt-4 max-md:w-full">
              <h3 className="block text-sm font-medium mb-4">
                Enter Title For Video
              </h3>
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                placeholder="Enter Video Title"
                className="w-full p-2 rounded-md  bg-gray-700  text-white focus:outline-none focus:ring-2 focus:ring-teal-900"
              />
            </div>

            <div className=" w-2/5 max-md:w-full relative flex justify-center mt-12 max-md:mt-6 lg:mt-4 ">
              <button
                className="custom-button max-md:mb-2 text-black bg-teal-600 font-medium py-3 w-full px-5 rounded-md hover:bg-teal-500 shadow-lg transition duration-300"
                onClick={generateImage}
              >
                Generate Video
              </button>
            </div>

            {/* <div className="w-2/5 mt-8 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: "45%" }}
            ></div>
          </div> */}
          </div>

          {/* <div className="flex justify-center w-full lg:w-[70%] mt-4 bg-gradient-to-br from-teal-900 via-gray-800 to-black-300 backdrop-blur-md rounded-lg p-6 shadow-lg">
          <CloneVideo />
        </div> */}
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div
              className="w-full bg-black max-w-md update-popup shadow-lg rounded-lg p-6 relative"
              style={{ background: "black" }}
            >
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="flex justify-end w-3 cursor-pointer shrink-0 fill-white   hover:fill-red-500 float-right"
                onClick={closeModal}
                viewBox="0 0 320.591 320.591"
              >
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  data-original="#000000"
                ></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  data-original="#000000"
                ></path>
              </svg>

              {/* Modal Content */}
              <div className="my-8 text-center">
                <h4 className="text-white text-l font-semibold mt-4">
                  Take a Photo
                </h4>
              </div>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotWidth={300}
                className=" rounded shadow mb-4"
                videoConstraints={videoConstraints}
              />
              <div className="flex justify-center  space-x-4">
                <button
                  onClick={capturePhoto}
                  className="flex justify-center items-center capture-button text-black bg-teal-600 font-medium py-3 w-full px-5 rounded-md hover:bg-teal-500 shadow-lg transition duration-300 mb-4"
                >
                  <FaCamera size={20} />
                  Capture
                </button>
              </div>
            </div>
          </div>
        )}
        {isSuccessModalOpen && (
          <div className=" fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
            <div className="w-full max-w-md  shadow-lg rounded-lg p-6 relative delete-popup">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 cursor-pointer shrink-0 fill-white  hover:fill-red-500 float-right"
                onClick={() => setIsSuccessModalOpen(false)}
                viewBox="0 0 320.591 320.591"
              >
                <path
                  d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                  data-original="#000000"
                ></path>
                <path
                  d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                  data-original="#000000"
                ></path>
              </svg>

              <div className="my-8 text-left">
                {successModalmessage.split(".").map((msg, i) => (
                  <>
                    {msg && (
                      <ul className="list-disc text-white" key={i}>
                        <li>
                          <h4 className="text-white text-sm font-semibold mt-4">
                            {msg}
                          </h4>
                        </li>
                      </ul>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        )}

        {isVideoModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black  z-[1000] ">
            <div className=" w-full h-full max-w-screen-sm">
              {/* Close Button */}
              <button
                onClick={handleVideoCloseModal}
                className="absolute top-4 right-4 z-10 text-2xl hover:text-red-600 text-white px-1 py-0 border border-white rounded-md"
              >
                <IoMdClose />
              </button>

              <video
                className="w-full h-full object-contain " // Changed to object-contain to prevent cropping
                controls
                autoPlay
              >
                <source
                  src="https://imas-ai-demo.s3.eu-central-003.backblazeb2.com/singing_selfie_video+(1).mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
